@charset "UTF-8";

@import "definitions";

$tile_size: 160px;
$tile_size_larger: 12.5vw;
$tile_size_largest: 10vw;

#no_script {
  position: relative;
  width: 100%;
  background: #f00;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  margin: 0;
  font-size: 14px;
  z-index: 100;
}


/* structure
====================================================================================================================================== */

html,
body {
  width: 100%;
}

html {
  scroll-behavior: smooth;
  height: 100%;
}

body {
  overflow-y: scroll;
  overflow-x: hidden;
  min-width: 1280px;
  background-color: #fff;
  color: $origin_color;
}

#container {
  width: 100%;
  font-size: 1.0625rem;
  line-height: 2;
  overflow: hidden;
}

.wrapper {
  display: flow-root;
  margin: 0 auto;
  width: 1000px;
}

/* 中ページ
====================================================================================================================================== */
#page_title_wrap{
  background-color: $gray;
  background-image: linear-gradient(0deg, transparent calc(100% - 2px), #fff calc(100% - 2px)),
                    linear-gradient(90deg, transparent calc(100% - 2px), #fff calc(100% - 2px));
  background-size: $tile_size $tile_size;
  background-repeat: repeat;
  background-position: right top;
  min-height: calc(#{$tile_size} * 1.5);
  margin-bottom: 90px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
#navibar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 13px 20px 0;
  font-size: 0.85rem;
  font-weight: normal;
  text-align: right;
  line-height: 1.5;
  margin-bottom: 7.5em;
  p {
    margin-bottom: 0;
  }
  a{
    font-weight: inherit;
  }
}

@media screen and (min-width: 1280px) {
  #page_title_wrap{
    background-size: $tile_size_larger $tile_size_larger;
    min-height: calc(#{$tile_size_larger} * 1.5);
  }
}
@media screen and (min-width: 1600px) {
  #page_title_wrap{
    background-size: $tile_size_largest $tile_size_largest;
    min-height: calc(#{$tile_size_largest} * 1.5);
  }
}


/* ページ内リンク
====================================================================================================================================== */
#page_links{
  margin: -90px 0 90px;
  padding-top: 30px;
  ul{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 3.5em;
    row-gap: 0.5em;
    li{
      margin: 0;
      padding: 0;
      &:before{
        display: none;
      }
      a{
        display: block;
        text-decoration: none;
        position: relative;
        padding-left: 1em;
        font-weight: normal;
        &:before{
          content: "";
          display: block;
          background: none;
          border: {
            right: 1px solid $origin_color;
            bottom: 1px solid $origin_color;
          }
          position: absolute;
          top: 45%;
          left: 0;
          translate: 0 -50%;
          rotate: 45deg;
          width: 0.5em;
          aspect-ratio: 1;
        }
      }
    }
  }
}
@media (hover: hover) {
  #page_links{
    ul{
      li{
        a:hover{
          text-decoration: underline;
        }
      }
    }
  }
}


/* header
====================================================================================================================================== */
#header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding-left: 95px;
}

#site_title {
  margin-bottom: 0;
  font-size: 2.6563rem;
  font-weight: bold;
  white-space: nowrap;
  background-color: #fff;
  a{
    text-decoration: none;
  }
  .inner{
    display: flex;
    align-items: center;
    gap: 0.3em;
  }
  .en{
    font-family: $font_teko;
    font-weight: 500;
  }
  .ja{
    font-size: 0.32em;
  }
}

@media (hover: hover) {
  #site_title{
    a:hover{
      opacity: 0.7;
    }
  }
}


#sp_menu_bg{
  display: none;
}
#sp_menu_area{
  display: none;
}


/* globalnavi
====================================================================================================================================== */
$gnav_width: 160px;
#globalnavi{
  ul.nav_list{
    height: 100%;
    display: grid;
    grid-template-columns: repeat(5, $gnav_width);
    grid-column-gap: 1px;
    margin-bottom: 0;
    li{
      font-size: 1.8062rem;
      font-weight: bold;
      text-align: left;
      line-height: 1.2;
      position: relative;
      overflow: hidden;
      a{
        display: block;
        aspect-ratio: 1;
        color: #fff;
        text-decoration: none;
        background-color: $origin_color;
        position: relative;
        overflow: hidden;
        &:before{
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          width: calc(100% + tan(20deg) * #{$gnav_width});
          height: 100%;
          background-color: #fff;
          translate: -100%;
          transform: skew(-20deg);
          transform-origin: right top;
          transition: .3s;
        }
      }
      span{
        display: block;
      }
      .inner{
        width: 100%;
        height: 100%;
        padding: 16% 0 0 16%;
        background-image: url(../images/icon_arrow_white_rb.svg);
        background-repeat: no-repeat;
        background-position: right 6px bottom 8px;
        background-size: 15px auto;
        transition: .3s;
        position: relative;
        z-index: 2;
      }
      .en{
        font-family: $font_teko;
        font-weight: 500;
        letter-spacing: 0.01em;
      }
      .ja{
        font-size: 0.47em;
      }
      &.link_recruit{
        a{
          background-color: $blue;
        }
      }
    }
  }
  .sp_menu_close{
    display: none;
  }
}

@media (hover: hover) {
  #globalnavi{
    ul.nav_list{
      li{
        a:hover{
          color: $origin_color;
          &:before{
            translate: 0;
          }
          .inner{
            background-image: url(../images/icon_arrow_black_rb.svg);
          }
        }
      }
    }
  }
}

$gnav_width_larger: 12.5vw;
@media screen and (min-width: 1280px) {
  #globalnavi{
    ul.nav_list{
      grid-template-columns: repeat(5, $gnav_width_larger);
      li{
        //width: $gnav_width_larger;
        a:hover{
          &:before{
            width: calc(100% + tan(20deg) * #{$gnav_width_larger});
          }
        }
      }
    }
  }
}

$gnav_width_largest: 10vw;
@media screen and (min-width: 1600px) {
  #globalnavi{
    ul.nav_list{
      grid-template-columns: repeat(5, $gnav_width_largest);
      li{
        //width: $gnav_width_largest;
        a:hover{
          &:before{
            width: calc(100% + tan(20deg) * #{$gnav_width_largest});
          }
        }
      }
    }
  }
}

/* クッキーの取り扱いについて
====================================================================================================================================== */
#cookie_acceptance{
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: $zindex_max - 10;
  width: 100%;
  background-color: rgba($color: #fff, $alpha: 0.9);
  font-size: 0.85rem;
  line-height: 1.7;
  padding: 1.4em 0;
  box-shadow: 0 0 20px rgba($color: #000, $alpha: 0.1);
  &.top{
    opacity: 0;
    animation: item_appear .3s ease-in-out both;
  }
  &.closed{
    animation: item_disappear .3s ease-in-out both;
  }
  .wrapper{
    width: 1120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  h2{
    font-size: 1.0625rem;
    font-weight: bold;
    line-height: 1.7;
    margin: 0;
    padding: 0;
    border: none;
  }
  p{
    width: 780px;
    margin-bottom: 0;
  }
  .button_area{
    width: 140px;
    margin-bottom: 0;
    a{
      min-width: initial;
      width: 100%;
      span{
        display: inline;
        padding: 0;
        background: none;
      }
    }
  }
}


/* トップページ loading
====================================================================================================================================== */
#loading{
  background-color: $gray;
  background-image: linear-gradient(0deg, transparent calc(100% - 2px), #fff calc(100% - 2px)),
                    linear-gradient(90deg, transparent calc(100% - 2px), #fff calc(100% - 2px));
  background-size: $tile_size $tile_size;
  background-repeat: repeat;
  background-position: right top;
  position: fixed;
  top: 0;
  left: 0;
  min-width: 1280px;
  width: 100vw;
  height: 100svh;
  z-index: $zindex_max;
  overflow: hidden;
  display: flex;align-items: flex-end;
}

#loading_animation{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: .3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .company_name{
    font-family: $font_teko;
    font-size: 1.4875rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.01em;
    margin-bottom: 0.6em;
  }
  .pulse-container {
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }
  .pulse-bubble {
    width: 12px;
    aspect-ratio: 1;
    font-size: 0;
    border-radius: 50%;
    background-color: $origin_color;
  }
  .pulse-bubble-1 {
      animation: pulse .6s ease 0s infinite alternate;
  }
  .pulse-bubble-2 {
      animation: pulse .6s ease .3s infinite alternate;
  }
  .pulse-bubble-3 {
      animation: pulse .6s ease .6s infinite alternate;
  }
}
@keyframes pulse {
  from {
    background-color: $origin_color;
    transform: scale(1);
  }
  to {
    background-color: #D2D5D9;
    transform: scale(.7);
  }
}

#loading_catch_area,
#main_visual{
  width: 100%;
  height: calc(100vh - #{$tile_size});
  display: grid;
  place-items: center;
  .catch_copy{
    white-space: nowrap;
    p{
      margin-bottom: 0;
    }

    .en{
      font-family: $font_teko;
      font-weight: 500;
      font-size: 9.0625rem;
      line-height: 0.95;
      letter-spacing: 0.01em;
      span{
        display: block;
        width: fit-content;
      }
    }
    .ja{
      font-size: 1.7rem;
      font-weight: bold;
      letter-spacing: 0.05em;
      line-height: 1.5;
      position: relative;
      span{
        display: block;
        width: fit-content;
        padding-left: 6.4em;
        position: relative;
        &:after{
          content: "";
          display: block;
          width: 5.88em;
          height: 1px;
          background-color: $origin_color;
          position: absolute;
          top: 50%;
          left: 0;
        }
      }
    }
  }
}

#loading_catch_area{
  opacity: 0;
  transition: .3s;
  .catch_copy{
    position: relative;
    /*
    &:before{
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 1px;
      height: 0;
      background-color: $origin_color;
    }
    */
    p{
      color: transparent;
      span{
        display: block;
      }
    }
    .ja{
      span:after{
        background-color: transparent;
      }
    }
    .en, .ja{
      span{
        position: relative;
        &:before{
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background-color: $origin_color;
        }
      }
    }
  }
}
#loading.loaded{
  animation: loading_disappear .3s 2.3s ease-in-out both;
  #loading_animation{
    opacity: 0;
  }
  #loading_catch_area{
    opacity: 1;
    .catch_copy{
      /*
      &:before{
        animation: line_extend 1.6s .4s ease-in-out both;
      }
      */
      p{
        animation: text_appear .1s 1.6s both;
      }
      .ja{
        span:after{
          animation: ja_text_line_appear .1s 1.6s both;
        }
      }
      .en, .ja{
        span{
          &:before{
            animation: catch_slide_in 1s ease-in-out both;
          }
        }
      }
      .en{
        .catch_line01{
          &:before{
            animation-delay: .7s
          }
        }
        .catch_line02{
          &:before{
            animation-delay: .8s
          }
        }
      }
      .ja{
        span{
          &:before{
            animation-delay: .9s
          }
        }
      }
    }
  }
}
#header.with_animation{
  #site_title a,
  #globalnavi ul li a{
    position: relative;
    &:after{
      content: "";
      display: block;
      background-color: #fff;
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      animation: header_item_appear .3s ease-in-out both;
    }
  }
}

#header.with_animation #site_title a:after,
#header.with_animation #globalnavi ul li a:after,
#cookie_acceptance.top:not(.closed) {
  animation-delay: 2.5s;
}

/*
@keyframes line_extend {
 0% { height: 0; }
 40% { height: 100%; }
 70% { height: 100%; }
 100% { height: 0; }
}
*/
@keyframes text_appear {
 from { color: transparent; }
 to { color: $origin_color; }
}
@keyframes ja_text_line_appear {
 from { background-color: transparent; }
 to { background-color: $origin_color; }
}
@keyframes catch_slide_in {
  0% {
    scale: 0 1;
    transform-origin: center left
  }
  50% {
    scale: 1 1;
    transform-origin: center left
  }
  70% {
    scale: 1 1;
    transform-origin: center right
  }
  100% {
    scale: 0 1;
    transform-origin: center right
  }
}
@keyframes loading_disappear {
 0% { opacity: 1; display: flex; }
 99% { opacity: 0; display: none; z-index: $zindex_max; }
 100% { opacity: 0; z-index: -999; }
}
@keyframes header_item_appear {
 from { width: 100%; }
 to { width: 0; }
}
@keyframes item_appear {
 from { opacity: 0; }
 to { opacity: 1; }
}
@keyframes item_disappear {
 0% { opacity: 1; }
 99% { opacity: 0; display: block; translate: 0; }
 100% { display: none; translate: 200%; }
}


/* トップページ main visual
====================================================================================================================================== */
#main_visual{
  background-color: $gray;
  background-image: linear-gradient(0deg, transparent calc(100% - 2px), #fff calc(100% - 2px)),
                    linear-gradient(90deg, transparent calc(100% - 2px), #fff calc(100% - 2px));
  background-size: $tile_size $tile_size;
  background-repeat: repeat;
  background-position: right top;
  position: relative;
  overflow: hidden;

  .circle_text{
    position: absolute;
    left: calc(50% + 20px);
    top: calc(50% - 200px);
    width: 800px;
    aspect-ratio: 1/1;
    p{
      position: absolute;
      bottom: 0;
      right: 0;
      margin: 0;
      img{
        width: 100%;
      }
    }
    .outside{
      animation: rotate_clockwise 60s linear infinite both;
    }
    .inside{
      animation: rotate_counterclockwise 60s linear infinite both;
    }
  }
}

@keyframes rotate_clockwise {
  from {
    rotate: 0;
  }
  to {
    rotate: 360deg;
  }
}
@keyframes rotate_counterclockwise {
  from {
    rotate: 0;
  }
  to {
    rotate: -360deg;
  }
}

@media screen and (min-width: 1280px) {
  #loading,
  #main_visual{
    background-size: $tile_size_larger $tile_size_larger;
  }
  #loading_catch_area,
  #main_visual{
    height: calc(100vh - #{$tile_size_larger});
  }
}
@media screen and (min-width: 1600px) {
  #loading,
  #main_visual{
    background-size: $tile_size_largest $tile_size_largest;
  }
  #loading_catch_area,
  #main_visual{
    height: calc(100vh - #{$tile_size_largest});
  }
}


/* contents
====================================================================================================================================== */

#conts {
  width: 100%;
  padding-bottom: 180px;
}


#solutions{
  background-color: $origin_color;
  color: #fff;
  font-weight: bold;
  overflow: hidden;
  padding: 10.5em 0 13em;
  position: relative;
  .text_area{
    width: 640px;
    p{
      width: 590px;
      margin-bottom: 0;
    }
    .lead_text{
      font-size: 1.9125rem;
      width: auto;
      margin-bottom: 1.1em;
    }
  }
  $solution_radius: 407px;
  .solutions_title_area{
    position: absolute;
    bottom: 0;
    right: 0;
    translate: 48% 40%;
    width: #{$solution_radius * 2};
    aspect-ratio: 1;
    .bottom_text{
      position: absolute;
      right: calc(100% + 0.05em);
      bottom: 31%;
      z-index: 1;
      font-family: $font_teko;
      font-size: 15.75rem;
      font-weight: 400;
      line-height: 1;
      letter-spacing: -0.01em;
      color: $origin_color;
      white-space: nowrap;
      opacity: 0.6;
      text-shadow: 1px 1px 0 #6d6d6d, -1px -1px 0 #6d6d6d,
                  -1px 1px 0 #6d6d6d,  1px -1px 0 #6d6d6d,
                    1px 0 0 #6d6d6d,   -1px  0 0 #6d6d6d,
                    0 1px 0 #6d6d6d,    0 -1px 0 #6d6d6d;
      margin-bottom: 0;
    }
    h2{
      padding: 0;
      margin: 0;
      font-family: $font_teko;
      font-size: 3.1875rem;
      font-weight: 300;
      line-height: 1.2;
      letter-spacing: 0.03em;
      color: #fff;
      text-align: center;
      position: absolute;
      top: 5.1em;
      left: 3.5em;
      z-index: 3;
    }
    ul{
      position: relative;
      z-index: 2;
      margin: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 1px solid #fff;
      li{
        padding: 0;
        margin: 0;
        font-family: $font_teko;
        font-size: 1.875rem;
        font-weight: 300;
        letter-spacing: 0.03em;
        color: $origin_color;
        background-color: $blue;
        text-align: center;
        width: 4.67em;
        aspect-ratio: 1;
        border-radius: 50%;
        display: grid;
        place-items: center;
        position: absolute;
        translate: -50% 50%;
        &:before{
          display: none;
        }
        &:nth-child(1){
          left: calc(cos(-5deg) * #{$solution_radius} * -1 + #{$solution_radius});
          bottom: calc(sin(-5deg) * #{$solution_radius} * -1 + #{$solution_radius});
        }
        &:nth-child(2){
          left: calc(cos(-29deg) * #{$solution_radius} * -1 + #{$solution_radius});
          bottom: calc(sin(-29deg) * #{$solution_radius} * -1 + #{$solution_radius});
        }
        &:nth-child(3){
          left: calc(cos(-53deg) * #{$solution_radius} * -1 + #{$solution_radius});
          bottom: calc(sin(-53deg) * #{$solution_radius} * -1 + #{$solution_radius});
        }
        &:nth-child(4){
          left: calc(cos(-77deg) * #{$solution_radius} * -1 + #{$solution_radius});
          bottom: calc(sin(-77deg) * #{$solution_radius} * -1 + #{$solution_radius});
        }
      }
    }
  }
}


.top_title{
  display: flex;
  align-items: center;
  border: none;
  padding: 0;
  line-height: 1;
  gap: 1.1em;
  margin-bottom: 1.8em;
  span{
    display: block;
  }
  .en{
    font-family: $font_teko;
    font-size: 3.6em;
    font-weight: 500;
    letter-spacing: 0.01em;
  }
  .ja{
    font-size: 0.75em;
    letter-spacing: 0.05em;
    padding-top: 0.4em;
  }
}


#service{
  border-top: 1px solid $origin_color;
  .wrapper{
    width: auto;
    display: grid; 
    grid-template-columns: 220px 1fr; 
    grid-template-rows: repeat(4, 620px);
    grid-template-areas: 
      "side_area service01"
      "side_area service02"
      "side_area service03"
      "side_area service04";
  }
  .side_area {
    grid-area: side_area;
    border-right: 1px solid $origin_color;
    border-bottom: 1px solid $origin_color;
    position: relative;
    .top_title{
      display: none;
    }
    .lead_text{
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 10;
      width: 220px;
      height: 100vh;
      &.fixed{
        position: fixed;
        inset: 0 auto auto 0;
      }
      &.bottom{
        position: absolute;
        inset: auto auto 0 0;
      }
      p{
        width: 1.88em;
        margin-bottom: 0;
        position: relative;
        img{
          width: 100%;
        }
        &:before{
          content: "";
          display: block;
          width: 300px;
          aspect-ratio: 600/685;
          background: url(../images/japan.png) no-repeat center bottom;
          background-size: contain;
          position: absolute;
          bottom: 0;
          left: 50%;
          translate: -50%;
        }
      }
    }
  }
  .service_link{
    background-color: $origin_color;
    overflow: hidden;
    border-bottom: 1px solid $origin_color;
    position: relative;
    &:after{
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      transition: .5s .2s ease-in-out;
      background-color: $origin_color;
    }
    &.window_in{
      &:after{
        width: 0;
      }
    }
    a, .no_link {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      position: relative;
      color: #fff;
      padding-left: 120px;
      transition: .3s;
    }
    a{
      text-decoration: none;
    }
    .bg_photo{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.6;
        transition: .3s;
      }
    }
    .num{
      position: absolute;
      top: 0;
      left: 0;
      font-family: $font_teko;
      font-weight: 500;
      font-size: 1.275rem;
      letter-spacing: 0.05em;
      background-color: $blue;
      text-align: center;
      width: 2.94em;
      display: grid;
      place-items: center;
      aspect-ratio: 1;
      margin-bottom: 0;
    }
    .english_title{
      margin-bottom: 0;
      font-family: $font_teko;
      font-size: 18.75rem;
      font-weight: 400;
      line-height: 1;
      letter-spacing: -0.01em;
      color: rgba($color: #fff, $alpha: 0.2);
      position: absolute;
      bottom: -0.28em;
      right: 0;
      white-space: nowrap;
    }
    .text_area{
      width: 410px;
      position: relative;
      text-shadow: 0 0 30px rgba($color: #000, $alpha: 0.5);
      font-weight: bold;
      h3{
        font-size: 2.55rem;
        font-weight: bold;
        text-align: left;
        line-height: 1;
        margin-bottom: 0.9em;
      }
      p{
        margin-bottom: 2.1em;
      }
      .more{
        display: grid;
        place-items: center;
        width: 153px;
        height: 2.4em;
        margin-bottom: 0;
        border: 1px solid $origin_color;
        background-color: $origin_color;
        font-family: 'Barlow Condensed', sans-serif;
        font-size: 1.275rem;
        line-height: 1;
        font-weight: 600;
        span{
          display: inline-block;
          padding-right: 1.37em;
          background: url(../images/icon_arrow_white_r.svg) no-repeat right 3px center;
          background-size: 0.75em auto;
          transition: .3s
        }
      }
    }
  }
  .service01 { grid-area: service01; }
  .service02 { grid-area: service02; }
  .service03 { grid-area: service03; }
  .service04 { grid-area: service04; }
}

@media (hover: hover) {
  #service{
    .service_link{
      a:hover{
        background-color: $origin_color;
        .bg_photo{
          img{
            scale: 1.1;
            opacity: 0.4;
          }
        }
        .text_area{
          .more{
            span{
              background-position: right center;
            }
          }
        }
      }
    }
  }
}

#news{
  padding: 10em 0;
  .button_area{
    margin-bottom: 0;
    a{
      width: 150px;
      min-width: initial;
    }
  }
}

#recruit{
  padding-top: 195px;
  position: relative;
  .photo_area{
    width: 1540px;
    aspect-ratio: 1540/400;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 2;
    translate: -50%;
    .photo{
      position: absolute;
      aspect-ratio: 1;
      overflow: hidden;
      translate: -100%;
      transition: .5s .2s ease-in-out;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border: 1px solid $origin_color;
        translate: calc(100% + 1px);
        transition: .5s .2s ease-in-out;
      }
    }
    &.window_in{
      .photo{
        translate: 0;
        img{
          translate: 0;
        }
      }
    }
    .photo01,
    .photo05{
      width: 21.43%;
    }
    .photo02,
    .photo07{
      width: 9.1%;
    }
    .photo03,
    .photo04,
    .photo06{
      width: 15.58%;
    }
    .photo01{
      top: 6.5%;
      left: 0;
    }
    .photo02{
      top: 0;
      left: 31.17%;
    }
    .photo03{
      bottom: 0;
      left: 22.73%;
    }
    .photo04{
      top: 21.25%;
      left: 41.56%;
    }
    .photo05{
      top: 6.5%;
      right: 20.19%;
    }
    .photo06{
      top: 0;
      right: 0;
    }
    .photo07{
      bottom: 0;
      right: 9.74%;
    }
  }
  .recruit_intro{
    padding: 350px 0 280px;
    background-color: #D2D5D9;
    position: relative;
    z-index: 1;
    overflow: hidden;
    .wrapper{
      display: flex;
      justify-content: space-between;
      position: relative;
    }
    .circle{
      border-radius: 50%;
      aspect-ratio: 1;
      background-color: $blue;
      position: absolute;
    }
    .circle01{
      width: 850px;
      top: 0;
      right: calc(50% + 215px);
      translate: 0 -50%;
    }
    .circle02{
      width: 165px;
      top: 100px;
      left: calc(50% + 12px);
      translate: 50%;
    }
    .circle03{
      width: 545px;
      bottom: 0;
      left: calc(50% + 370px);
      translate: 0 50%;
    }
    .top_title{
      display: block;
    }
    .copy_area{
      width: 590px;
      font-weight: bold;
      p{
        margin-bottom: 0;
      }
      .larger{
        font-size: 1.9125rem;
        margin-bottom: 0.7em;
      }
    }
  }
}

@keyframes photo_effect {
  from {
    background-color: $origin_color;
    transform: scale(1);
  }
  to {
    background-color: #D2D5D9;
    transform: scale(.7);
  }
}


#recruit_bottom{
  margin-top: 180px;
  h2{
    font-size: 1.9125rem;
    text-align: center;
    border: none;
    padding: 0;
    margin-bottom: 1.4em;
  }
}
.recruit_links{
  position: relative;
  z-index: 1;
  display: grid;
  grid-template-columns: repeat(6, 1fr); 
  grid-template-rows: repeat(2, max-content); 
  gap: 30px; 
  grid-template-areas: 
    "link01 link01 link01 link02 link02 link02"
    "link03 link03 link04 link04 link05 link05";
  &.top{
    margin-top: -165px;
  }
  .recruit_link_box{
    background-color: #fff;
    font-weight: bold;
    line-height: 1.5;
    a{
      display: block;
      height: 100%;
      text-decoration: none;
    }
  }
  .large_link{
    font-size: 3.1875rem;
    letter-spacing: 0.05em;
    overflow: hidden;
    border: 2px solid $origin_color;
    a{
      padding: 0.7em 0.9em;
      position: relative;
      height: auto;
      aspect-ratio: 485/450;
      &:before{
        content: "";
        display: block;
        width: 2.5em;
        aspect-ratio: 1;
        position: absolute;
        bottom: 0;
        right: 0;
        background-image: url(../images/recruit_link_right_bottom_red.svg);
        background-repeat: no-repeat;
        background-position: right bottom;
        background-size: contain;
      }
      &:after{
        content: "";
        display: block;
        width: 0.6em;
        aspect-ratio: 1;
        position: absolute;
        bottom: 15px;
        right: 15px;
        background-image: url(../images/icon_arrow_black_rb.svg);
        background-repeat: no-repeat;
        background-position: right bottom;
        background-size: contain;
        transition: .3s;
      }
    }
  }
  .link02{
    a{
      &:before{
        background-image: url(../images/recruit_link_right_bottom_blue.svg);
      }
    }
  }
  .small_link{
    aspect-ratio: 1;
    font-size: 1.7rem;
    letter-spacing: 0.03em;
    border: 1px solid $origin_color;
    a{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      &:after{
        content: "";
        display: block;
        width: 0.85em;
        aspect-ratio: 1;
        position: absolute;
        bottom: 13px;
        right: 13px;
        background-image: url(../images/icon_arrow_black_rb.svg);
        background-size: contain;
        transition: .3s;
      }
    }
    .fukidashi{
      display: block;
      width: 100%;
      height: 70%;
      background-image: radial-gradient(rgba(36,44,82,0.12) 15%, transparent 15%);
      background-size: 12px 12px;
      text-align: center;
      padding-top: 14%;
      position: relative;
      img{
        width: 83%;
        position: relative;
        z-index: 2;
      }
      &:after{
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 90px;
        background-color: #fff;
        clip-path: polygon(0 0, 100% 100%, 0 100%);
      }
    }
    .link_title{
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      display: block;
      width: 100%;
      padding: 0 0 0.8em 1.3em;
    }
  }
  .link01 { grid-area: link01; }
  .link02 { grid-area: link02; }
  .link03 { grid-area: link03; }
  .link04 { grid-area: link04; }
  .link05 { grid-area: link05; }
}

@media (hover: hover) {
  .recruit_links{
    .large_link{
      a:hover{
        opacity: 0.7;
        &:after{
          right: calc(15px - 3px);
          bottom: calc(15px - 3px);
        }
      }
    }
    .small_link{
      a:hover{
        opacity: 0.7;
        &:after{
          bottom: 10px;
          right: 10px;
        }
      }
    }
  }
}


/* footer
====================================================================================================================================== */

#footer {
  .bg_black{
    background-color: $origin_color;
    color: #fff;
    padding: 10.5em 0 3em;
  }
  .info_area{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 8em;
    .button_area{
      margin-bottom: 0;
      a{
        padding: 0;
        height: 8em;
        display: grid;
        place-items: center;
        width: 100%;
        min-width: initial;
        border-color: #fff;
        > span{
          display: flex;
          align-items: center;
          gap: 0.7em;
          padding: 0 2em 0 1.3em;
          background-image: url(../images/icon_arrow_white_r_l.svg);
          background-size: 1.3em auto;
        }
        .en{
          font-size: 3em;
          font-family: $font_teko;
          letter-spacing: 0.01em;
        }
      }
    }
  }
  .company_info{
    .company_name{
      margin-bottom: 1.5em;
      img{
        width: 273px;
      }
    }
  }
  .sitemap{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    margin-bottom: 80px;
  }
  .sitemap_block{
    dl{
      border-top: 1px solid rgba($color: #fff, $alpha: 0.5);
      padding: 1.6em 0;
      &:last-of-type{
        padding-bottom: 0;
      }
      a{
        text-decoration: none;
      }
      dt{
        a{
          color: #fff;
          font-weight: bold;
        }
      }
      dd{
        padding-top: 0.7em;
        font-size: 0.9em;
        a{
          color: #D2D5D9;
          font-weight: normal;
        }
      }
    }
  }

  .footer_links{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 0.9em;
    line-height: 1;
    gap: 2em;
    li{
      a{
        color: #D2D5D9;
        font-weight: normal;
        text-decoration: none;
      }
    }
  }

  .footer_bottom{
    text-align: center;
    padding: 3.5em 0 1.5em;
    .footer_banners{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 60px;
      margin-bottom: 1em;
      .logo_egg{
        width: 173px;
      }
      .logo_scala{
        width: 190px;
      }
      .logo_jpx{
        width: 100px;
      }
    }
    .group_info{
      margin-bottom: 2.3em;
      p{
        margin-bottom: 0;
      }
    }
    .copyright{
      display: block;
      font-size: 0.9563rem;
    }
  }
}
@media (hover: hover) {
  #footer{
    .info_area{
      .button_area{
        a:hover{
          > span{
            background-image: url(../images/icon_arrow_black_r_l.svg);
          }
        }
      }
    }
    .sitemap_block{
      dl{
        a:hover{
          text-decoration: underline;
        }
      }
    }
    .footer_links{
      li{
        a:hover{
          text-decoration: underline;
        }
      }
    }
  }
}

/* button_pagetop
====================================================================================================================================== */
#button_pagetop {
  position: fixed;
  width: 45px;
  right: 35px;
  bottom: 35px;
  z-index: #{$zindex_max - 20};
  a{
    display: block;
    transition: .5s;
    &.hide{
      opacity: 0;
      pointer-events: none;
    }
    img{
      width: 100%;
    }
  }
}

@media (hover: hover) {
  #button_pagetop a:hover{
    opacity: 0.5;
  }
}



